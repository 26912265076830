import React, { useState } from 'react';
import { Confirm, useNotify, useRefresh } from 'react-admin';
import PauseIcon from '@material-ui/icons/Pause';
import ResumeIcon from '@material-ui/icons/PlayArrow';

import IconButton from '../../common/IconButton';

import { pauseStarlinkServiceLine, resumeStarlinkServiceLine } from '../../../services/api';


const PauseResumeButton = ({ record, variant, color }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		let response;
		if(record.can_pause) {
			response = await pauseStarlinkServiceLine(record.id);
		}
		else if(record.can_resume) {
			response = await resumeStarlinkServiceLine(record.id);
		}
		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={record.can_pause ?  <PauseIcon /> : <ResumeIcon />} onClick={handleOpen} variant={variant} color={color}>{record.can_pause ? 'Pause' : 'Resume'}</IconButton>
			<Confirm
				isOpen={showDialog}
				title={record.can_pause ? 'Pause' : 'Resume'}
				content={`Are you sure you want to ${record.can_pause ? 'Pause' : 'Resume'} ?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default PauseResumeButton;
