import React, { useState } from 'react';
import { Confirm, useNotify, useRefresh } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '../../common/IconButton';

import { optInStarlinkServiceLine, optOutStarlinkServiceLine } from '../../../services/api';


const OptInOptOutButton = ({ record, variant, color }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		let response;
		if(!record.is_opted_in) {
			response = await optInStarlinkServiceLine(record.id);
		}
		else {
			response = await optOutStarlinkServiceLine(record.id);
		}
		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={record.is_opted_in ? <ClearIcon /> : <CheckIcon />} onClick={handleOpen} variant={variant} color={color}>{record.is_opted_in ? 'Opt Out' : 'Opt In'}</IconButton>
			<Confirm
				isOpen={showDialog}
				title={record.is_opted_in ? 'Opt Out' : 'Opt In'}
				content={`Are you sure you want to ${record.is_opted_in ? 'Opt Out' : 'Opt In'} ?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default OptInOptOutButton;
