import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

import DeviceRentSoldField from './fields/DeviceRentSoldField';
import DeviceBuySmField from './fields/DeviceBuySmField';
import DeviceSupplierField from './fields/DeviceSupplierField';
import DeviceArchiveButton from './DeviceActionsButtons/DeviceArchiveButton';

const optionText = choice => choice && choice.reference ? choice.reference : '';
const customerOptionText = choice => choice && choice.name ? choice.name : '';

const DeviceEdit = ({ classes, ...props }) => (
    <Edit {...props} archiveButton={<DeviceArchiveButton />}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="serial" disabled />
            <TextInput source="name" />
            <ReferenceInput label="Product" source="product_id" reference="device_products" filter={{ managed: true, archived: false }}  sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <ReferenceInput label="Owner" source="owner_id" reference="customers"  sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={customerOptionText} />
            </ReferenceInput>
            <DeviceSupplierField source="supplier" />
            <DeviceBuySmField source="buy_sm" label="Supplier Billing Mode"/>
            <DeviceRentSoldField source="rent_sold" label="VENN Billing Mode" />
            <BooleanInput source="reserved" />
            <BooleanInput source="is_new" />
            <TextInput source="notes" options={{ multiline: true }} rows="10" />
            <TextInput label="Data Limit Alert (GB)" source="data_limit" />
            <TextInput label="Data Limit Contacts (CSV)" source="data_limit_contacts" options={{ multiline: true }} rows="4"/>
            <TextInput source="customer_notes" options={{ multiline: true }} rows="4"/>
        </SimpleForm>
    </Edit>
);

export default DeviceEdit;