import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

import DeviceBuySmField from './fields/DeviceBuySmField';
import DeviceSupplierField from './fields/DeviceSupplierField';

const optionText = choice => choice && choice.reference ? choice.reference : '';
const customerOptionText = choice => choice && choice.name ? choice.name : '';

const DeviceCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="serial" />
            <ReferenceInput label="Product" source="product_id" reference="device_products" filter={{ managed: true, archived: false }} sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <DeviceSupplierField source="supplier" />
            <DeviceBuySmField source="buy_sm" label="Supplier Billing Mode"/>
            <ReferenceInput label="Owner" source="owner_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={customerOptionText} />
            </ReferenceInput>
            <TextInput source="notes" options={{ multiline: true }} rows="10" />
        </SimpleForm>
    </Create>
);

export default DeviceCreate;
