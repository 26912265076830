import React from 'react';
import { TextInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import StarlinkEventRow from './StarlinkEventRow';
import StarlinkEventCodeField from './StarlinkEventCodeField';


const StarlinkEventFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <StarlinkEventCodeField label="Code" source="code" alwaysOn />
    </Filter>
);

const StarlinkEventList = (props) => (
    <List {...props} filters={<StarlinkEventFilter />}>
        <StarlinkEventRow />
    </List>
);

export default StarlinkEventList;