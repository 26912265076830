import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'ethernet_slow_10mbps', name: 'ethernet_slow_10mbps' },
	{ id: 'ethernet_slow_100mbps', name: 'ethernet_slow_100mbps'},
	{ id: 'power_supply_thermal_throttling', name: 'power_supply_thermal_throttling' },
    { id: 'actuator_motor_stuck', name: 'actuator_motor_stuck' },
    { id: 'mast_not_vertical', name: 'mast_not_vertical' },
	{ id: 'unable_to_align', name: 'unable_to_align' },
	{ id: 'thermal_shutdown', name: 'thermal_shutdown' },
	{ id: 'thermal_throttle', name: 'thermal_throttle' },
	{ id: 'disabled_data_usage_exceeded_quota', name: 'disabled_data_usage_exceeded_quota' },
	{ id: 'disabled_no_service_in_ocean', name: 'disabled_no_service_in_ocean' },
	{ id: 'disabled_invalid_country', name: 'disabled_invalid_country' },
	{ id: 'disabled_cell_is_disabled', name: 'disabled_cell_is_disabled' },
	{ id: 'disabled_blocked_country', name: 'disabled_blocked_country' },
];


const StarlinkEventCodeField = ({ classes, record, source = "code", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default StarlinkEventCodeField;
