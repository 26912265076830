import React from 'react';
import LinkIcon from '@material-ui/icons/Link';

import IconButton from '../../common/IconButton';


const StarlinkServiceLineURLButton = ({ record, variant, color }) => {
    return (
        <IconButton icon={<LinkIcon />} component="a" href={`https://www.starlink.com/account/dashboard/service-line/${record.service_line_number}`}  target="__blank" variant={variant} color={color}>Starlink Page</IconButton>
    );
};

export default StarlinkServiceLineURLButton;