import React, { useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { Title, TextInput, ReferenceInput, AutocompleteInput, Toolbar, SaveButton, useNotify } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import product_items from './';
import { CreateActions } from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import ProductItemSupplierField from './inputs/ProductItemSupplierInput';

import { bulkCreateProductItem } from '../../services/api';


const useStyles = makeStyles({
	summary: {
		marginTop: '15px',

		'& h3': {
			marginTop: '15px'
		},
		'& p': {
			whiteSpace: 'pre-wrap'
		}
	}
});

const optionText = choice => choice && choice.reference ? choice.reference : '';

const CustomSaveButton = ({ onClick, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		if(formState.values.product_items_refs) {
			let data = new FormData();
			data.append("product_items_refs", formState.values.product_items_refs);

			if(formState.values.product_id) {
				data.append("product_id", formState.values.product_id);
			}
			if(formState.values.supplier) {
				data.append("supplier", formState.values.supplier);
			}

			data.append("dry", false);
			const results = await bulkCreateProductItem(data);

			if(results.success) {
				let message = `${results.created.length} element${results.created.length > 1 ? 's' : ''} created`;
				if(results.existing.length > 0) {
					message += `\n${results.existing.length} element${results.existing.length > 1 ? 's' : ''} existing`;
				}
				notify(message);

				if(results.created.length > 0 || results.existing.length > 0) {
					form.change('product_items_refs', null);
					form.change('product_id', null);
					form.change('supplier', null);
				}

				onClick({ results, summary: message });
			}
		}
	};

	return <SaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const CustomToolbar = ({ onSave, ...props }) => (
	<Toolbar {...props}>
		<CustomSaveButton onClick={onSave} />
	</Toolbar>
);

const DeviceBulkCreate = () => {
	const [results, setResults] = useState(null);
	const [summary, setSummary] = useState(null);
	const classes = useStyles();

	const breadcrumbPath = [
		{ url: '/product_items', title: "Product Items", icon: <product_items.icon /> },
		{
			url: '/product_items/bulk-create',
			title: "Bulk create",
			isActive: true
		}
	];

	const handleSave = async (result) => {
		setResults(result.results);
		setSummary(result.summary);
	}

	const renderSummary = () => {
		if (!results) {
			return null;
		}

		return (
			<Card className={classes.summary}>
				{results && (
					<CardContent>
						<Typography variant="h6" gutterBottom>Summary</Typography>
						<Typography>{summary}</Typography>

						{results.created.length > 0 && (
							<React.Fragment>
								<Typography variant="subtitle1">Created</Typography>
								{results.created.map((device =>
									<Typography key={device.serial}>{device.serial}</Typography>
								))}
							</React.Fragment>
						)}

						{results.existing.length > 0 && (
							<React.Fragment>
								<Typography variant="subtitle1">Existing</Typography>
								{results.existing.map((device =>
									<Typography key={device.serial}>{device.serial}</Typography>
								))}
							</React.Fragment>
						)}
					</CardContent>
				)}
			</Card>
		);
	}

	return (
		<>
			<CreateActions basePath="/product_items" hasList={true} breadcrumb={breadcrumbPath} />
			<Card>
				<Title title="Create Product Items" />
				<SimpleForm resource="product_items" toolbar={<CustomToolbar onSave={handleSave} />}>
					<ReferenceInput label="Product" source="product_id" reference="device_products" filter={{ managed: false, archived: false }} sort={{ field: "name", order: "ASC" }} allowEmpty>
						<AutocompleteInput optionText={optionText} />
					</ReferenceInput>
					<ProductItemSupplierField />
					<TextInput source="product_items_refs" label="Serial numbers" rows="20" multiline fullWidth />
				</SimpleForm>
			</Card>

			{renderSummary()}
		</>
	);
};

export default DeviceBulkCreate;