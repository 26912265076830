import React, { useState } from 'react';
import StartIcon from '@material-ui/icons/PlayArrow';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import IconButton from '../common/IconButton';

const PackageStartButton = ({ record, variant, color }) => {
    const translate = useTranslate();

    if (!record) {
        return null;
    }

    return (
        <IconButton component={Link} to={`/packages/${record.id}/start`} icon={<StartIcon />} variant={variant} color={color}>{translate('Start')}</IconButton>
    );
};

export default PackageStartButton;
