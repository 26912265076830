import React from 'react';
import { DateInput, Query, ListButton, ShowButton, Title, Toolbar, SaveButton, TextInput } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useHistory, useParams } from 'react-router-dom';
import { useFormState } from 'react-final-form';

import SimpleForm from '../common/SimpleForm';
import Breadcrumb from '../common/Breadcrumb';
import CardActions from '../common/CardActions';

import packages from './index';

import { startPackage } from '../../services/api';
import { Typography } from '@material-ui/core';

const Actions = ({ record, breadcrumbPath }) => (
    <CardActions>
        <Breadcrumb path={breadcrumbPath} />
        <div>
            <ListButton basePath="/packages" />
            <ShowButton basePath="/packages" record={record} />
        </div>
    </CardActions>
);

const CustomToolbar = ({ onSave, disabled, packageId, ...props }) => {
    const formState = useFormState();

    const handleClick = async () => {
        if (formState.valid) {
            let data = new FormData();
            data.append("date", formState.values.date);

            try {
                const response = await startPackage(packageId, data);
                if (response.id && onSave) {
                    onSave();
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} disabled={disabled} />
        </Toolbar>
    );
};

const PackageStart = () => {
    const history = useHistory();
    const { package_id } = useParams();
    const id = package_id;

    const handleSave = () => {
        history.push(`/packages/${id}/show`);;
    }

    return (
        <Query type="GET_ONE" resource="packages" payload={{ id: id }}>
            {({ data }) => {
                if (!data) {
                    return null;
                }

                const breadcrumbPath = [
                    { url: '/packages', title: "Packages", icon: <packages.icon /> },
                    {
                        url: `/packages/${data.id}/show`,
                        title: (data ? data.reference : data.id)
                    },
                    {
                        url: `/packages/${data.id}/start`,
                        title: 'Start',
                        isActive: true
                    }
                ];

                return (
                    <>
                        <Title title="Start" />
                        <CardContent>
                            <Actions record={data} breadcrumbPath={breadcrumbPath} />
                        </CardContent>
                        <Card>
                            <SimpleForm
                                redirect="show"
                                record={data}
                                resource="packages"
                                toolbar={<CustomToolbar onSave={handleSave} packageId={id} />}
                                initialValues={{ date: new Date().toLocaleDateString('en-US') }}
                            >
                                <Typography>Set start date for all services without start date</Typography>
                                <DateInput label="Start Date" source="date" />
                            </SimpleForm>
                        </Card>
                    </>
                );
            }}
        </Query>
    );
};

export default PackageStart;