import React from 'react';
import { TextInput, NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';


const optionText = choice => choice && choice.reference ? choice.reference : '';
const salesOrderOptionText = choice => choice && choice.reference ? choice.reference : '';

const redirect = (basePath, id, data, location) => {
    if (data?.package_id && location?.state?.record?.package_id === data.package_id) {
        return `/packages/${data.package_id}/show`;
    }
    return basePath;
};

const PackageLineCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={(basePath, id, data) => redirect(basePath, id, data, props.location)}>
            <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }}>
                <AutocompleteInput optionText={salesOrderOptionText} />
            </ReferenceInput>
            <ReferenceInput label="Device Product" source="device_product_id" reference="device_products" filter={{ archived: false }} sort={{ field: 'reference', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <NumberInput source="qty" />
            <NumberInput source="price" />
            <TextInput source="notes" label="Notes" options={{ multiline: true }} rows="4" />
            <TextInput source="serials" label="Serials" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Create>
);

export default PackageLineCreate;
