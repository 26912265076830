import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import DeviceProductRow from './DeviceProductRow';

import { API_URL } from '../../services/settings';

const DeviceProductListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/device_products/export.xls`}>Export</IconButton>
        </ListActions>
    );
};


const DeviceProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Managed" source="managed" alwaysOn />
        <BooleanInput label="Virtual" source="virtual" alwaysOn />
        <BooleanInput label="Accessory" source="accessory" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    managed: true,
    accessory: false,
    virtual: false,
    archived: false,
}

const DeviceProductList = props => (
	<List {...props} filters={<DeviceProductFilter/>} filterDefaultValues={ filterDefaultValues } perPage={50} exporter={false} actions={<DeviceProductListActions />}>
		<DeviceProductRow />
	</List>
);

export default DeviceProductList;