import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';

import IconButton from '../../common/IconButton';

import { syncStarlinkServiceLine } from '../../../services/api';


const StarlinkServiceLineSyncButton = ({ record, variant, color }) => {
	const [showDialog, setShowDialog] = useState(false);
	const refresh = useRefresh();

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleDeactivate = async () => {
		handleClose();
		await syncStarlinkServiceLine(record.id);
		refresh();
	};

	if (!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<RefreshIcon />} variant={variant} color={color} onClick={handleOpen}>Sync</IconButton>
			<Confirm
				isOpen={showDialog}
				title={`Sync`}
				content={`Are you sure you want to sync this service line?`}
				onConfirm={handleDeactivate}
				onClose={handleClose}
			/>
		</>
	);
};

export default StarlinkServiceLineSyncButton;