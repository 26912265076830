import React from 'react';
import { TextField, ReferenceField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import ChipField from '../common/fields/ChipField';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import StatusField from './fields/StatusField';
import ExportPDFButton from './InvoiceActionsButtons/ExportPDFButton';
import SendInvoiceButton from './InvoiceActionsButtons/SendInvoiceButton';
import ReverseInvoiceButton from './InvoiceActionsButtons/ReverseInvoiceButton';
import CloneInvoiceButton from './InvoiceActionsButtons/CloneInvoiceButton';
import PaidInvoiceButton from './InvoiceActionsButtons/PaidInvoiceButton';

const CustomRecordSplitButton = ({ record, ...props }) => (
    <RecordSplitButton record={record} {...props} hasEdit={record.status === 'draft'}>
        <ExportPDFButton record={record} />
        <SendInvoiceButton record={record} />
        <ReverseInvoiceButton record={record} />
        <CloneInvoiceButton record={record} />
        <PaidInvoiceButton record={record} />
    </RecordSplitButton>
);

const InvoiceRow = ({ children, ...props }) => (
    <Datagrid {...props}>
        <LinkFieldButton label="Number" sortBy="number">
            <TextField source="number" />
        </LinkFieldButton>
        <CustomerReferenceField />
        <TextField source="purchase_order" label="Reference" />
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
			<TextField source="reference" />
		</ReferenceField>
        <ChipField source="invoice_type" />
        <DateField source="invoiced_at" />
        <StatusField />
        <NumberCurrencyField source="total_untaxed" />
        <CustomRecordSplitButton />
        {children}
    </Datagrid>
);

export default InvoiceRow;