import React from 'react';
import { ReferenceField, TextField, SimpleShowLayout, DateField } from 'react-admin';

import Datagrid from '../common/Datagrid';


const StarlinkEventDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="date" />
        <TextField source="usage" />
    </SimpleShowLayout>
);

const StarlinkEventRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkEventDrawer {...props} />} {...props}>
        <DateField source="timestamp" showTime={true} />
        <TextField source="code" />
        <TextField source="source" />
        <ReferenceField label="Starlink Terminal" source="starlink_terminal_id" reference="starlink_terminals" link="show" allowEmpty>
            <TextField source="terminal_id" />
        </ReferenceField>
        {children}
    </Datagrid>
);

StarlinkEventRow.defaultProps = {
    basePath: '/starlink_events'
};

export default StarlinkEventRow;