import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { AutocompleteInput, SaveButton, DateInput, BooleanInput, useNotify, Title, ListButton, ShowButton, Query, Toolbar, Link, TextField, FunctionField, SimpleShowLayout, TextInput, Confirm, ReferenceInput, } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import SimpleForm from '../common/SimpleForm';
import BackToStockReasonInput from './BackToStockReasonInput';
import Breadcrumb from '../common/Breadcrumb';
import CardActions from '../common/CardActions';
import BooleanField from '../common/fields/BooleanField';

import devices from './index';
import { returnDevice } from '../../services/api';

const checklist = [
	"Automatic IP Settings Removed for device in csv file",
	"SpeedFusion Licence Removed on FH for device",
	"Tag for Wifi SSID Push removed in Incontrol Wifi/Portal Configuration",
	"Tag for VLAN Push removed in Incontrol VLAN Configuration",
	"Device moved to _STOCK in Incontrol",
	"Notes and tags on device removed from Incontrol",
	"Flash config removed from device",
	"Device Resetted",
	"Accessories Updated in Inventory if any",
	"Special Sim cards disabled at provider or given to other person to handle",
	"Pending Orders Updated",
	"Accounting has needed info to bill Data Usage",
];

const optionText = choice => choice.name ? choice.name : '';

const CustomToolbar = ({ onSubmit, record, loading, ...props }) => {
	const [submitted, setSubmitted] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleSubmit = async () => {
		handleClose();

		let formData = new FormData();
		formData.append("reason", formState.values.reason || '');
		formData.append("disassemble", formState.values.disassemble);
		formData.append("missing_or_broken_accessories", formState.values.missing_or_broken_accessories || false);
		formData.append("date", new Date(formState.values.date).toISOString());
		formData.append("notes", formState.values.notes || '');
		formData.append("site_id", formState.values.site_id || '');

		const response = await returnDevice(record?.id, formData);
		form.reset();

		if (response.success === false) {
			notify('Device is not back to stock', 'warning');
		}
		else {
			setSubmitted(true);
			onSubmit(response);
		}
	};

	const handleCheck = () => {
		if (record.commitment_date) {
			handleOpen();
		}
		else {
			handleSubmit();
		}
	};

	const handleConfirm = () => {
		handleSubmit();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if (submitted) {
		return null;
	}

	return (
		<>
			<Confirm
				isOpen={showDialog}
				title="Back to stock"
				content={`Are you sure you want to perform a back to stock since there is a commitment date?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
			<Toolbar {...props}>
				<SaveButton handleSubmit={handleCheck} handleSubmitWithRedirect={handleCheck} disabled={loading} />
			</Toolbar>
		</>
	);
};

const DeviceBackToStock = ({ record, ...props }) => {
	const [result, setResult] = useState();
	const { id } = props.match.params;

	const handleSave = async (response) => {
		setResult(response);
	};

	return (
		<Query type="GET_ONE" resource="devices" payload={{ id }}>
			{({ data: record, loading }) => {
				const breadcrumbPath = [
					{ url: '/devices', title: "Devices", icon: <devices.icon /> },
					{
						url: `/devices/${record?.id}/show`,
						title: record?.name ? record.name : record?.id
					},
					{
						url: `/devices/${record?.id}/back-to-stock`,
						title: "Back to stock",
						isActive: true
					}
				];

				return (
					<>
						<CardActions>
							<Breadcrumb path={breadcrumbPath} />
							<div>
								<ListButton basePath="/devices" />
								<ShowButton basePath="/devices" record={record} />
							</div>
						</CardActions>
						<Card>
							<Title title="Back to stock" />
							{result ? (
								<CardContent>
									<Typography variant="h5" gutterBottom>Summary</Typography>

									<Typography variant="h6">Device {record.serial} returned to stock</Typography>
									<SimpleShowLayout
										record={{
											...record,
											disassemble: result.disassemble,
											reason: result.reason,
											last_total_usage: result.last_total_usage,
											current_month_usage: result.current_month_usage
										}}
									>
										<TextField source="name" />
										<TextField source="serial" />
										<BooleanField source="disassemble" />
										<TextField source="reason" />
										<FunctionField label="Product" render={record => (record.product && `${record.product?.reference} ${record.product?.category && `/  ${record.product.category}`}`)} />
										<TextField source="ic_name" />
										<FunctionField label="Customer" render={record => <Link to={`/customers/${record.customer_id}/show`}>{record.customer?.name}</Link>} />
										<FunctionField label="Site" render={record => <Link to={`/sites/${record.site_id}/show`}>{record.site?.name}</Link>} />
										<FunctionField label="New fetched usage" render={record => `${record.last_total_usage} GB`} />
										<FunctionField label="Current month usage" render={record => `${record.current_month_usage} GB`} />
										<TextField label="IC Server" source="ic_server_name" />
										<TextField label="IC Organisation" source="ic_organization_name" />
									</SimpleShowLayout>

									{result?.ops_sim_cards?.length > 0 && result?.disassemble && (
										<>
											<Typography variant="h6">Removed Simcards from OPS Config</Typography>
											<TableContainer>
												<Table size="small">
													<TableHead>
														<TableRow>
															<TableCell>ICCID</TableCell>
															<TableCell>Provider</TableCell>
															<TableCell>Supplier</TableCell>
															<TableCell>Carrier</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{result.ops_sim_cards.map((sim) => (
															<TableRow key={sim.id}>
																<TableCell><Link to={`/simcards/${sim.id}/show`}>{sim.iccid}</Link></TableCell>
																<TableCell>{sim.provider?.name}</TableCell>
																<TableCell>{sim.supplier_name}</TableCell>
																<TableCell>{sim.carrier_name}</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</>
									)}

									{result?.lines?.length > 0 && (
										<>
											<Typography variant="h6">Detached Lines</Typography>
											<TableContainer>
												<Table size="small">
													<TableHead>
														<TableRow>
															<TableCell>Subscription</TableCell>
															<TableCell>Provider</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{result.lines.map((line) => (
															<TableRow key={line.id}>
																<TableCell><Link to={`/lines/${line.id}/show`}>{line.subscription_id}</Link></TableCell>
																<TableCell>{line.provider?.name}</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</>
									)}

									<Box mt={3}>
										<Grid container direction="column">
											<Typography variant="h5" gutterBottom>Checklist</Typography>
											{checklist.map((label, i) => (
												<FormControlLabel key={`checklist_${i}`} control={<Checkbox color="primary" />} label={label} />
											))}
										</Grid>
									</Box>
								</CardContent>
							) : (
								<SimpleForm
									toolbar={<CustomToolbar onSubmit={handleSave} loading={loading} />}
									initialValues={{ date: new Date(), disassemble: true }}
									record={record}
								>
									<DateInput label="Commitment date" source="commitment_date" disabled />
									<ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} filter={{ is_stock: true }} >
                                        <AutocompleteInput optionText={optionText} />
                                    </ReferenceInput>
									<BooleanInput source="disassemble" label="Disassemble SIMs" />
									<BooleanInput source="missing_or_broken_accessories" label="Missing Or Broken Accessories" />
									<DateInput label="Was delivered at office on" source="date" />
									<BackToStockReasonInput source="reason" />
									<TextInput label="Notes ( Only sent in the email )" source="notes" options={{ multiline: true }} rows="4" />
									<>
										<Typography variant="body1">After submitting, please wait. The process take some time to fetch last usage and sending report by mail.</Typography>
									</>
								</SimpleForm>
							)}
						</Card>
					</>
				);
			}}
		</Query>
	);
};

export default DeviceBackToStock;